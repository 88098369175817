var cordovaApp = {
    f7: null,
    /*
     This method hides splashscreen after 2 seconds
     */
    handleSplashscreen: function () {
        var f7 = cordovaApp.f7;
        if (!window.navigator.splashscreen || f7.device.electron)
            return;
        setTimeout(() => {
            window.navigator.splashscreen.hide();
        }, 2000);
    },
    /*
     This method prevents back button tap to exit from app on android.
     And allows to exit app on backbutton double tap
     */
    handleAndroidBackButton: function () {
        var f7 = cordovaApp.f7;
        if (f7.device.electron)
            return;
        cordovaApp.backButtonTimestamp = new Date().getTime();
        document.addEventListener('backbutton', function (e) {
            if (f7.popup.get('.popup') == undefined && f7.dialog.get() == undefined) {
                f7.views.current.router.back();
            } else {
                if (f7.popup.get('.popup') != undefined) {
                    f7.popup.get('.popup').close();
                }
                if (f7.dialog.get() != undefined) {
                    f7.dialog.close();
                }
            }
            if (new Date().getTime() - cordovaApp.backButtonTimestamp < 550) {
                cordovaApp.backButtonTimestamp = new Date().getTime();
                if (window.navigator.app && window.navigator.app.exitApp) {
//                    window.navigator.app.exitApp();
                    navigator.app.exitApp();
                }
                return true;
            }
            cordovaApp.backButtonTimestamp = new Date().getTime();
            e.preventDefault();
            return false;
        }, false);
    },
    /*
     This method prevents back button tap to exit from app on android.
     And allows to exit app on backbutton double tap
     */
//    handleAndroidBackButton: function () {
//        var f7 = cordovaApp.f7;
//        if (f7.device.electron)
//            return;
//        cordovaApp.backButtonTimestamp = new Date().getTime();
//        document.addEventListener('backbutton', function (e) {
//            if (new Date().getTime() - cordovaApp.backButtonTimestamp < 250) {
//                cordovaApp.backButtonTimestamp = new Date().getTime();
//                if (window.navigator.app && window.navigator.app.exitApp) {
//                    window.navigator.app.exitApp();
//                }
//                return true;
//            }
//            cordovaApp.backButtonTimestamp = new Date().getTime();
//            e.preventDefault();
//            return false;
//        }, false);
//    },
    onBackKeyDown: function () {
        var f7 = cordovaApp.f7;
        if (f7.device.electron)
            return;
        document.addEventListener("backbutton", function (e) {
            var leftp = f7.panel.left && f7.panel.left.opened;
            var rightp = f7.panel.right && f7.panel.right.opened;
            if (leftp || rightp) {
                f7.panel.close();
                return false;
            } else if ($$('.modal-in').length > 0) {
                f7.dialog.close();
                return false;
            } else if (f7.views.main.router.url == '/') {
                f7.dialog.confirm('Are you sure you want to exit?', '', function () {
                    navigator.f7.exitApp();
                },
                        function () {});
            } else {
                mainView.router.back();
            }

        }, false);
    },
    /*
     This method does the following:
     - provides cross-platform view "shrinking" on keyboard open/close
     - hides keyboard accessory bar for all inputs except where it required
     */
    handleKeyboard: function () {
        var f7 = cordovaApp.f7;
        if (!window.Keyboard || !window.Keyboard.shrinkView || f7.device.electron)
            return;
        var $ = f7.$;
        window.Keyboard.shrinkView(false);
        window.Keyboard.disableScrollingInShrinkView(true);
        window.Keyboard.hideFormAccessoryBar(true);
        window.addEventListener('keyboardWillShow', () => {
            f7.input.scrollIntoView(document.activeElement, 0, true, true);
        });
        window.addEventListener('keyboardDidShow', () => {
            f7.input.scrollIntoView(document.activeElement, 0, true, true);
        });
        window.addEventListener('keyboardDidHide', () => {
            if (document.activeElement && $(document.activeElement).parents('.messagebar').length) {
                return;
            }
            window.Keyboard.hideFormAccessoryBar(false);
        });
        window.addEventListener('keyboardHeightWillChange', (event) => {
            var keyboardHeight = event.keyboardHeight;
            if (keyboardHeight > 0) {
                // Keyboard is going to be opened
                document.body.style.height = `calc(100% - ${keyboardHeight}px)`;
                $('html').addClass('device-with-keyboard');
            } else {
                // Keyboard is going to be closed
                document.body.style.height = '';
                $('html').removeClass('device-with-keyboard');
            }

        });
        $(document).on('touchstart', 'input, textarea, select', function (e) {
            var nodeName = e.target.nodeName.toLowerCase();
            var type = e.target.type;
            var showForTypes = ['datetime-local', 'time', 'date', 'datetime'];
            if (nodeName === 'select' || showForTypes.indexOf(type) >= 0) {
                window.Keyboard.hideFormAccessoryBar(false);
            } else {
                window.Keyboard.hideFormAccessoryBar(true);
            }
        }, true);
    },
    /*
     This method does the following:
     - change the status bar page color and text style
     */
    statusBar: function () {
        StatusBar.overlaysWebView(false);
        StatusBar.backgroundColorByHexString("#fff"); // => #333333
        StatusBar.styleDefault();
    },

    //================
    //====OneSignal Notification
    receivedEvent: function (id) {

        console.log('Function Gitted One Signal')
        var app = cordovaApp.f7;
        console.log(app);
        //START ONESIGNAL CODE
        //Remove this method to stop OneSignal Debugging
        window.plugins.OneSignal.setLogLevel({logLevel: 6, visualLevel: 0});

        var notificationOpenedCallback = function (jsonData) {
            //console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
            //console.log(jsonData);
            var redirectionUrl = jsonData.notification.payload.additionalData.redirectionUrl;
            console.log(redirectionUrl);
            app.views.main.router.navigate(redirectionUrl);
        };
        // Set your iOS Settings
        var iosSettings = {};
        iosSettings["kOSSettingsKeyAutoPrompt"] = false;
        iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;

        window.plugins.OneSignal
                .startInit("53671949-ae24-40fd-8eed-8c9ca31a53b9")
                .handleNotificationOpened(notificationOpenedCallback)
                .iOSSettings(iosSettings)
                .inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.Notification)
                .endInit();

        //get player id
        window.plugins.OneSignal.getIds(function (state) {
            var playerID = state.userId;
            console.log("CORDOVA JS RESPONSE", playerID);
            localStorage.setItem("playerID", playerID);
        }, err => {
            console.log("err estate ids", err);
        });

        // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
        window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
            console.log("User accepted notifications: " + accepted);
        });
        //END ONESIGNAL CODE
    },
    receivedEventV2: function(id){
        var app = cordovaApp.f7;
        // Add to index.js or the first page that loads with your app.
        // document.addEventListener('deviceready', OneSignalInit, false);
        
            // Uncomment to set OneSignal device logging to VERBOSE  
            // window.plugins.OneSignal.setLogLevel(6, 0);
            
            // NOTE: Update the setAppId value below with your OneSignal AppId.
            window.plugins.OneSignal.setAppId("53671949-ae24-40fd-8eed-8c9ca31a53b9");
            window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
                console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
                var redirectionUrl = jsonData.notification.payload.additionalData.redirectionUrl;
                console.log(redirectionUrl);
                app.views.main.router.navigate(redirectionUrl);
            });
            
           // window.plugins.OneSignal.setSMSNumber("+11234567890");

            //Prompts the user for notification permissions.
            //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
            window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
                console.log("User accepted notifications: " + accepted);
            });

            window.plugins.OneSignal.addSubscriptionObserver(function (state) {
                 if (state.to.isSubscribed) {
                    localStorage.setItem("playerID",state.to.userId); 
                    }
                });
             
        
    },
    init: function (f7) {
        // Save f7 instance
        cordovaApp.f7 = f7;

        // Handle Android back button
        cordovaApp.handleAndroidBackButton();
//        cordovaApp.onBackKeyDown();

        // Handle Statusbar
        cordovaApp.handleSplashscreen();

        // Handle Keyboard
        cordovaApp.handleKeyboard();

        //========= handle ststus bar
        cordovaApp.statusBar();

        //Calling Onesignal Event
        // cordovaApp.receivedEvent('deviceready');
        cordovaApp.receivedEventV2('deviceready');

        cordova.getAppVersion.getVersionNumber(function (version) {
            console.log("app version");
            console.log(version);
        });
        cordova.getAppVersion.getVersionCode(function (version) {
            console.log("app getVersionCode");
            console.log(version);
        });

    },
};
export default cordovaApp;
