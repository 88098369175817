export default {
  id: 'c422253786',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page register_page">\r\n        <div class="navbar">\r\n            <div class="navbar-inner sliding">\r\n                <div class="left">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon icon-back"></i>\r\n                        <span class="if-not-md">Back</span>\r\n                    </a>\r\n                </div>\r\n            </div>\r\n        </div>\r\n        <div class="page-content">\r\n            <div class="success_page">\r\n                <div class="success_img">\r\n                    <img class="element-animation" src="static/success.svg" style="width: 25%;">\r\n                </div>\r\n                <div class="success_txt">\r\n                    <span class="paid">Paid Successfully</span>\r\n                    <span class="payment">\u20B9 59</span>\r\n                    <span class="name">Dr. Biswajit Chowdhury Singh</span>\r\n                    <span class="apt_id">Appointment ID: mdoc85579925675544</span>\r\n                    <span class="aptm_date">Appointment Date : 23 Sep 2019</span>\r\n                    <div class="qr_code">\r\n                        <img src="static/frame.png">\r\n                    </div>\r\n                    <span class="transctn">Transaction ID: 263893877379</span>\r\n                    <span>03:21 PM, <span>14 Sep 2019</span></span>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};