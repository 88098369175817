
import mainSlider from '../pages/main_slider.f7.html';
import mobileVerification from '../pages/mobile_verification.f7.html';

import HomePage from '../pages/home.f7.html';
import leftPanel from '../pages/left-panel.f7.html';
import AboutPage from '../pages/about_us.f7.html';
import FormPage from '../pages/form.f7.html';
import DoctorsDepFilter from '../pages/doctors_list.f7.html';
import DoctorsHosFilter from '../pages/doctors_list_hospital.f7.html';
import booking from '../pages/booking_form.f7.html';
import sign_up from '../pages/sign_up.f7.html';
import changePassword from '../pages/changePassword.f7.html';
//import sign_in from '../pages/sign_in.f7.html';
//import otp_varification from '../pages/otp_varification.f7.html';
import paymentSuccess from '../pages/paymentSuccess.f7.html';
import edit_profile from '../pages/edit_profile.f7.html';
import appointment from '../pages/appointment.f7.html';
import appointmentDetails from '../pages/appointmentDetails.f7.html';
import searchDoctor from '../pages/doctors_search_list.f7.html';
import coupon from '../pages/coupon.f7.html';
import applyCoupon from '../pages/apply_coupon.f7.html';
import refer from '../pages/refer.f7.html';
import support from '../pages/support.f7.html';
import query from '../pages/query.f7.html';
import terms from '../pages/tems_of_use.f7.html';

import terms1 from '../pages/tems_of_use_1.f7.html';
import terms2 from '../pages/tems_of_use_2.f7.html';

import doctor_profile from '../pages/doctor_profile.f7.html';
import notification from '../pages/notification.f7.html';
//import edit_profile from '../pages/edit_profile.f7.html';

import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

//--------- v4 ---------------
import subscrition from '../pages/booking_subscrition.f7.html';
import subscrition_details from '../pages/subscrition_details_paynow.f7.html';

var routes = [

    {
        path: '/left-panel/',
        panel: {
            component: leftPanel,
        },
    },

    {
        path: '/home/',
        async: function (routeTo, routeFrom, resolve, reject) {
            var router = this;
            var app = router.app;

//            document.addEventListener("deviceready", function () {
            var saveToken = localStorage.getItem("token");
            var oneSignal = localStorage.getItem("playerID");
            
//            console.log("token - " + saveToken);
            if (saveToken == null || saveToken == '') {
                localStorage.setItem("token", "");
                resolve({
                    component: mainSlider,
                });
            } else {
                app.preloader.show();
                var city = localStorage.getItem('city');
                var url = app.data.remoteUrl + 'home/' + saveToken + '/' + city;
                // window.plugins.OneSignal.setSMSNumber(localStorage.getItem('mobile'));
                app.request({
                    url: url,
                    method: 'POST',
                    dataType: 'json',
                    data: {oneSignal: oneSignal},
                    statusCode: {
                        404: function (xhr) {
                            alert('page not found');
                        },
                    },
                    success: function (data) {
                        app.preloader.hide();
                        if (data.status) {
                            resolve(
                                    {
                                        component: HomePage,
                                    },
                                    {
                                        context: {
                                            department: data.department,
                                            hospital: data.hospital,
                                            slider: data.slider,
                                            city: data.city
                                        }
                                    }
                                )
                        } else {
                            localStorage.setItem("token", "");
                            resolve({
                                component: mobileVerification,
                            });
                        }

                    },
                    complete: function () {
                        app.preloader.hide();
                    },
                    error: function () {
                        app.preloader.hide();
                        app.dialog.alert("Please Check Internet Connection !!", "Alert");
                    },
                    beforeSend: function () {
                        app.preloader.show();
                    },
                    //dataType: 'json',
                });
            }
//            }, false);
        },
        on: {
            pageAfterIn(e, page) {
                page.router.clearPreviousHistory();
            },
        },
    },
//
//    {
//        path: '/',
//        async: function (routeTo, routeFrom, resolve, reject) {
//            // Router instance
//            var router = this;
//            // App instance
//            var app = router.app;
//            // Show Preloader
//            app.preloader.show();
//            var saveToken = localStorage.getItem("token");
//            var url = app.data.remoteUrl + 'home/' + saveToken + '/';
//            app.request.json(url, function (data) {
//                console.log(data);
//                app.preloader.hide();
//                if (data.status) {
//                    resolve(
//                            {
//                                component: HomePage,
//                            },
//                            {
//                                context: {
//                                    department: data.department,
//                                    hospital: data.hospital,
//                                    slider: data.slider,
//                                    city: data.city
//                                }
//                            }
//                    );
//                } else {
//                    localStorage.setItem("token", "");
//                    resolve({
//                        component: sign_in,
//                    });
//                }
//
//            });
//        },
//        on: {
//            pageAfterIn(e, page) {
//                page.router.clearPreviousHistory();
//            },
//        },
//    },
//
    {
        path: '/sign_up/:mobile/',
        component: sign_up,
    },
    {
        path: '/changePassword/:mobile/',
        component: changePassword,
    },
    {
        path: '/mobileVerification/',
        // component: mobileVerification,
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            var url = app.data.remoteUrl + 'preMobileCheck/';
            console.log(url);
            app.request.json(url, function (data) {
                console.log(data);
                app.preloader.hide();
                resolve(
                        {
                            component: mobileVerification,
                        },
                        {
                            context: {
                                captcha: data.img_url,
                                captchaImage: data.image,
                            }
                        }
                );

            });
        },
//        on: {
//            pageAfterIn(e, page) {
//                page.router.clearPreviousHistory();
//            },
//        },
    },
    {
        path: '/sign_in/',
        component: mainSlider,
        on: {
            pageAfterIn(e, page) {
                page.router.clearPreviousHistory();
            },
        },
    },
//    {
//        path: '/otp_varification/',
//        component: otp_varification,
//    },
    {
        path: '/about/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            var url = app.data.remoteUrl + 'getAbout/';
            console.log(url);
            app.request.json(url, function (data) {
                console.log(data);
                app.preloader.hide();
                resolve(
                        {
                            component: AboutPage,
                        },
                        {
                            context: {
                                data: data.data,
                            }
                        }
                );

            });
        },
    },
    {
        path: '/edit_profile/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();

            var saveToken = localStorage.getItem("token");
            var url = app.data.remoteUrl + 'getMyProfile/' + saveToken + '/';
            console.log(url);
            app.request.json(url, function (data) {
                console.log(data);
                app.preloader.hide();
                if (data.status) {
                    resolve(
                            {
                                component: edit_profile,
                            },
                            {
                                context: {
                                    data: data.data,
                                }
                            }
                    );
                } else {
                    localStorage.setItem("token", "");
                    resolve({
                        component: mobileVerification,
                    });
                }

            });
        },
    },
    {
        path: '/form/',
        component: FormPage,
    },

    {
        path: '/doctors/:dep/:dep_name/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var dep = routeTo.params.dep;
            var dep_name = routeTo.params.dep_name;

            var city = localStorage.getItem('city');
            var url = app.data.remoteUrl + 'getDoctor/dep/' + dep + '/' + city + '/';
            app.request.json(url, function (data) {
                console.log(data);
                app.preloader.hide();
                resolve(
                        {
                            component: DoctorsDepFilter,
                        },
                        {
                            context: {
                                data: data,
//                                depart: routeTo.params.dep_name,
                            }
                        }
                );

            });
        },
    },

    {
        path: '/hospital/:hospital/:hospital_name/',
        async: function (routeTo, routeFrom, resolve, reject) {
            var router = this;
            var app = router.app;
            app.preloader.show();
            var hospital = routeTo.params.hospital;
            var city = localStorage.getItem('city');
            var url = app.data.remoteUrl + 'getDoctor/hos/' + hospital + '/' + city + '/';
            app.request.json(url, function (data) {
                console.log(data);
                app.preloader.hide();
                resolve(
                        {
                            component: DoctorsHosFilter,
                        },
                        {
                            context: {
                                data: data,
                            }
                        }
                );

            });
        },
    },

    {
        path: '/searchDoctor/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();

            var city = localStorage.getItem('city');
            var url = app.data.remoteUrl + 'getDoctor/0/0/' + city + '/';
            console.log(url);
            app.request.json(url, function (data) {
                console.log(data);
                app.preloader.hide();
                resolve(
                        {
                            component: searchDoctor,
                        },
                        {
                            context: {
                                data: data,
//                                depart: routeTo.params.dep_name,
                            }
                        }
                );

            });
        },
    },

    {
        path: '/booking/:doc/:type',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var doc = routeTo.params.doc;
            var type = routeTo.params.type;
            var pageId = 'booking_form'+ app.methods.pageid();
            var saveToken = localStorage.getItem("token");
            var url = app.data.remoteUrl + 'preBookDetails/' + doc + '/' + type + '/'+ saveToken +'/';

            // app.methods.authHeader();
            app.request.json(url, function (data) {
                console.log(data);
                app.preloader.hide();
                resolve(
                        {
                            component: booking,
                        },
                        {
                            context: {
                                data: data,
                                type: type,
                                pageId : pageId
                            }
                        }
                );

            });
        },
    },
    {
        path: '/paymentSuccess/',
        component: paymentSuccess,
    },
    {
        path: '/appointment/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var doc = routeTo.params.doc;
            var token = localStorage.getItem("token");
            var url = app.data.remoteUrl + 'getBookedDetails/' + token + '/';
            app.request.json(url, function (data) {
//                data = JSON.parse(data);
                console.log(data);
                app.preloader.hide();
                if (data.status) {
                    resolve(
                            {
                                component: appointment,
                            },
                            {
                                context: {
                                    data: data,
                                }
                            }
                    );
                } else {
                    localStorage.setItem("token", "");
                    resolve({
                        component: mobileVerification,
                    });

                }

            });
        },
    },
    {
        path: '/appointmentDetails/:appId',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var appId = routeTo.params.appId;
            var token = localStorage.getItem("token");
            var url = app.data.remoteUrl + 'getAppointDetails/' + token + '/' + appId + '/';
            app.request.json(url, function (data) {
//                data = JSON.parse(data);
                console.log(data);
                app.preloader.hide();
                if (data.status) {
                    resolve(
                            {
                                component: appointmentDetails,
                            },
                            {
                                context: {
                                    data: data.data,
                                }
                            }
                    );
                } else {
                    localStorage.setItem("token", "");
                    resolve({
                        component: mobileVerification,
                    });

                }

            });
        },
    },

    {
        path: '/coupon/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var token = localStorage.getItem("token");

            var url = app.data.remoteUrl + 'meraCouponDekhao/' + token + '/';
            app.request.json(url, function (data) {
//                data = JSON.parse(data);
                console.log(data);
                app.preloader.hide();
                if (data.status) {
                    resolve(
                            {
                                component: coupon,
                            },
                            {
                                context: {
                                    data: data.data,
                                }
                            }
                    );
                } else {
                    localStorage.setItem("token", "");
                    resolve({
                        component: mobileVerification,
                    });

                }

            });
        },
    },

    {
        path: '/applyCoupon/:doc/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var token = localStorage.getItem("token");
            var doc = routeTo.params.doc;
            var url = app.data.remoteUrl + 'meraCouponDekhao/' + token + '/' + doc + '/';
            app.request.json(url, function (data) {
//                data = JSON.parse(data);
                console.log(data);
                app.preloader.hide();
                if (data.status) {
                    resolve(
                            {
                                component: applyCoupon,
                            },
                            {
                                context: {
                                    data: data.data,
                                }
                            }
                    );
                } else {
                    localStorage.setItem("token", "");
                    resolve({
                        component: mobileVerification,
                    });

                }

            });
        },
    },

    {
        path: '/refer/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var token = localStorage.getItem("token");
            var doc = routeTo.params.doc;
            var url = app.data.remoteUrl + 'meraReferraDekhao/' + token + '/';
            app.request.json(url, function (data) {
//                data = JSON.parse(data);
                console.log(data);
                app.preloader.hide();
                if (data.status) {
                    resolve(
                            {
                                component: refer,
                            },
                            {
                                context: {
                                    data: data.data,
                                }
                            }
                    );
                } else {
                    localStorage.setItem("token", "");
                    resolve({
                        component: mobileVerification,
                    });

                }

            });
        },
    },

//    {
//        path: '/refer/',
//        component: refer,
//    },
    {
        path: '/support/',
        component: support,
    },
    {
        path: '/query/',
        component: query,
    },

    {
        path: '/doctor_profile/',
        component: doctor_profile,
    },
    {
        path: '/notification/',
        component: notification,
    },
    {
        path: '/terms/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            var url = app.data.remoteUrl + 'getTerm/';
            console.log(url);
            app.request.json(url, function (data) {
//                console.log(data);
                app.preloader.hide();
                resolve(
                        {
                            component: terms,
                        },
                        {
                            context: {
                                data: data.data,
                            }
                        }
                );

            });
        },
    },

    {
        path: '/terms1/',
        component: terms1,
    },

    {
        path: '/terms2/',
        component: terms2,
    },

    {
        path: '/subscription/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var token = localStorage.getItem("token");
            var doc = routeTo.params.doc;
            var url = app.data.remoteUrl + 'subscription/' + token + '/';
            app.request.json(url, function (data) {
//                data = JSON.parse(data);
                console.log(data);
                app.preloader.hide();
                if (data.status) {
                    resolve(
                            {
                                component: subscrition,
                            },
                            {
                                context: {
                                    data: data.data,
                                }
                            }
                    );
                } else {
                    localStorage.setItem("token", "");
                    resolve({
                        component: mobileVerification,
                    });

                }

            });
        },
    },
    {
        path: '/subscription-details/:subspackage/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;
            // App instance
            var app = router.app;
            // Show Preloader
            app.preloader.show();
            // Dep ID from request
            var token = localStorage.getItem("token");

            var subspackage = routeTo.params.subspackage;

            var url = app.data.remoteUrl + 'subscriptionorder/'+ subspackage +'/' + token + '/';
            app.request.json(url, function (data) {
                console.log(data);
                app.preloader.hide();
                if (data.status) {
                    resolve(
                            {
                                component: subscrition_details,
                            },
                            {
                                context: {
                                    data: data.data,
                                }
                            }
                    );
                } else {
                    localStorage.setItem("token", "");
                    resolve({
                        component: mobileVerification,
                    });

                }

            });
        },
    },





    {
        path: '/dynamic-route/blog/:blogId/post/:postId/',
        component: DynamicRoutePage,
    },
    {
        path: '/request-and-load/user/:userId/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;

            // App instance
            var app = router.app;

            // Show Preloader
            app.preloader.show();

            // User ID from request
            var userId = routeTo.params.userId;

            // Simulate Ajax Request
            setTimeout(function () {
                // We got user data from request
                var user = {
                    firstName: 'Vladimir',
                    lastName: 'Kharlampidi',
                    about: 'Hello, i am creator of Framework7! Hope you like it!',
                    links: [
                        {
                            title: 'Framework7 Website',
                            url: 'http://framework7.io',
                        },
                        {
                            title: 'Framework7 Forum',
                            url: 'http://forum.framework7.io',
                        },
                    ]
                };
                // Hide Preloader
                app.preloader.hide();

                // Resolve route to load page
                resolve(
                        {
                            component: RequestAndLoad,
                        },
                        {
                            context: {
                                user: user,
                            }
                        }
                );
            }, 1000);
        },
    },
    {
        path: '(.*)',
        component: NotFoundPage,
    },
];

export default routes;