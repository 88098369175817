
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  methods: {
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert('Hello World');
    },
    updateTimeSlot: function () {
      var self = this;
      console.log('call holam');
    }
  },
  on: {
    pageInit(e, page) {
      const app = this.$app;
      const $$ = this.$;
      const pageId = 'booking_subccription_' + app.methods.pageid();
    }
  },
  id: 'f3b2f32fc9',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page" data-name="';
      r += c(ctx_1.pageId, ctx_1);
      r += '" id="';
      r += c(ctx_1.pageId, ctx_1);
      r += '" data-ignore-cache="false" removeElements=\'true\'\n        router.off=\'on\'>\n        <div class="navbar">\n            <div class="navbar-inner sliding">\n                <div class="left">\n                    <a href="#" class="link back">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">Package</div>\n\n            </div>\n        </div>\n        <div class="page-content">\n              \n                ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n\n                <div class="card booking" id="amntdetail">\n                    <div class="card-header">\n                        <div class="apply_text">\n\n                            ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type == 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <i class="material-icons">person</i>\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n                            ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type == 1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <i class="material-icons">group</i>\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n                            <span>';
          r += c(ctx_2.name, ctx_2);
          r += '</span>\n                        </div>\n                        <div>\u20B9';
          r += c(ctx_2.amount, ctx_2);
          r += '</div>\n                    </div>\n                    <div class="card-content padding">\n                        <!-- <div class="content-header">Payment Details</div> -->\n\n                        <div class="payment-summary">\n                            ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.package_item, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <div class="payment-details">\n                                <p>\n                                    ';
              r += c(ctx_3, ctx_3);
              r += '\n                                </p>\n                            </div>\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        </div>\n\n                    </div>\n                    <div class="card-footer ">\n                        <!-- <div class="payment-summary"> -->\n                            \n\n                            <!-- <div> -->\n                                <!-- <p class="fee-terms"><sup class="terms">*</sup>Appointment Fee is payable at Doctor\'s Clinic.</p> -->\n                            <!-- </div> -->\n\n                        <!-- </div> -->\n                        ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.is_subcribed, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <div class=" text-color-theme">\n                            Expiry Date  \n                            \n                        </div>\n                        <div>';
              r += c(ctx_3.valid_upto, ctx_3);
              r += '</div>\n                        <!-- <div class="pay-Now-btn">\n                            <button class="button button-fill" type="submit" disabled>Subscribed</button>\n                        </div> -->\n\n\n\n                        ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                        <a href="/subscription-details/';
              r += c(ctx_3.id, ctx_3);
              r += '/" class="pay-Now-btn subscript_btn_pay" >\n                            <button class="button button-fill" type="submit">Subscrib Now</button>\n                        </a>\n                        ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        \n                       \n                    </div>\n                </div>\n\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n\n        </div>\n    </div>\n\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    