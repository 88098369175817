import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
// Import F7 Styles
import 'framework7/css/framework7.bundle.css';
// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';




var app = new Framework7({
    root: '#app', // App root element
    id: 'com.mdoc.app', // App bundle ID
    name: 'mDOC', // App name
    theme: 'auto', // Automatic theme detection
    pushState: true,
    panel: {
        swipe: 'left',
    },
    // App root data
    data: function () {
        return {
            user: {
                name: localStorage.getItem("name"),
                token: localStorage.getItem("token"),
                city: localStorage.getItem("city"), //================= default value is Ranchi
            },
            // remoteUrl: 'https://localhost/mdoc/api/api/app_v4_3_0/',
            // remoteBaseUrl: 'https://localhost/mdoc/api/',
            // assetsUrl: 'https://localhost/mdoc/api/api/appv3_0_1/', //new updated

        //     remoteUrl: 'https://api-mdoc.test/App_v4_3_0/',
        //    remoteBaseUrl: 'https://api-mdoc.test/',
        //    assetsUrl: 'https://api-mdoc.test/appv3_0_1/',



           remoteUrl: 'https://app.mdoc.in/App_v4_3_0/',
           remoteBaseUrl: 'https://app.mdoc.in/',
           assetsUrl: 'https://app.mdoc.in/appv3_0_1/',

        };
    },
    // App root methods
    methods: {
        helloWorld: function () {
            app.dialog.alert('Hello');
        },
        myAjax: function (formId, myPost, mySuccess, formDataPost) {

            if (formDataPost != 'undefine' || formDataPost != null) {
                var formData = app.form.convertToData(formId);
            } else {
                var formData = formId;
            }
            console.log("form data");
            console.log(formData);
            console.log(app.data.remoteUrl + myPost);
            app.request({
                url: app.data.remoteUrl + myPost,
                method: 'POST',
                data: {data: formData},
                statusCode: {
                    404: function (xhr) {
                        alert('page not found');
                    },
                },
                success: function (returndata) {
                    //console.log(returndata);
                    mySuccess(returndata);
                    console.log('Success');
                },
                complete: function () {
                    console.log('complete');
                    app.preloader.hide();
                },
                error: function () {
                    app.preloader.hide();
                    app.dialog.alert("Please Check Internet Connection !!", "Alert");
                    console.log('error');
                },
                beforeSend: function () {
                    app.preloader.show();
                    console.log('beforeSend');
                },
                //dataType: 'json',
            });
        },
        authHeader: function () {
            Framework7.request.setup({
              // beforeSend: function (xhr) {
              //     // xhr.setRequestHeader ('Authorization', 'Token '+ app.methods.localstoreout('token').access);
              //     xhr.setRequestHeader ('Authorization', 'Token ');
              // }
              headers: {
                // 'Authorization', 'Token '+ app.methods.localstoreout('token').access
                // 'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'mdoc-token': localStorage.getItem("token"),
                'Accept': 'application/json'
              }
            });
        },
        createToken: function () {
            localStorage.setItem("token", "");
        },
        pageid: function(){
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = (Math.random() * 16) | 0,
                    v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
    },
    // App routes
    routes: routes,
    // Register service worker
    serviceWorker: Framework7.device.cordova ? {} : {
        path: '/service-worker.js',
    },
    // Input settings
    input: {
        scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
        scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
        overlay: Framework7.device.cordova && Framework7.device.ios || 'auto',
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
        androidBackgroundColor: '#ffffff',
        androidTextColor: 'black'
    },
    on: {
        init: function () {
            var f7 = this;
            if (f7.device.cordova) {
                // Init cordova APIs (see cordova-app.js)
                cordovaApp.init(f7);
            }

        },
    },
});
/*
 -- All common function will goes here ---
 */

function updateDate(e) {
    console.log(e);
}



//================on home page update=============================
var mainView = app.views.create('.view-main', {
    url: '/home/',
});
//============== call onload function ==========
function onLoad() {
    document.addEventListener("deviceready", onDeviceReady, false);
}

//------------ call on device ready -----------
function onDeviceReady() {
    //============= call login UI =============
    saveToken = localStorage.getItem("token");
    if (saveToken == null || saveToken == '') {
        localStorage.setItem("token", "");
        app.loginScreen.open('#my-login-screen');
    } else {
        app.data.token = saveToken;
        app.data.user.name = localStorage.getItem("name");
    }
    // console.log('jit');
    // console.log(device);
    var deviceDetails = JSON.stringify(device);
    // console.log(deviceDetails);
    // console.log('sri')

//    city = localStorage.getItem("city");
//    if (city == null || city == '') {
//        localStorage.setItem("city", "1");
//    } else {
//        var ss = app.smartSelect.get('#homePage .smart-select');
//        ss.setValue(city);
//
//    }

    //    console.log(app);
    updateTodayDate();
}
//=================== success Function =====================
//------------------- This is default function
function mySuccess(returnData) {
   // console.log(returnData);
    returnData = JSON.parse(returnData);
    if (returnData['status']) {
        app.dialog.alert(returnData['msg']);
    } else {
        app.dialog.alert(returnData['msg']);
        logout();
    }
}

//------------------- This function is for after login success
function loginSuccess(returnData) {
    //cosole.log("jit login");
    returnData = JSON.parse(returnData);
    if (returnData['status']) {
        storeSession(returnData);
        //app.dialog.alert(returnData['msg']);
        // app.loginScreen.close('#my-login-screen');
    } else {
        app.dialog.alert(returnData['msg']);
    }
}
//-------------------  after registration success --------------------
function registrationSuccess(returnData) {
    returnData = JSON.parse(returnData);
    if (returnData['status']) {
        storeSession(returnData);
        app.dialog.alert(returnData['msg'], "THANK YOU !!");
        app.views.main.router.navigate('/home/');
        app.views.main.router.clearPreviousHistory();
    } else {
        app.dialog.alert(returnData['msg']);
    }
}
// //-------------------  after booking success --------------------
// function bookingSuccess(returnData) {
//     returnData = JSON.parse(returnData);
//     if (returnData['status']) {
//         payment(returnData['book_id']);
//         //app.dialog.alert(returnData['msg'], "THANK YOU !!");
//         //        app.views.main.router.navigate('/paymentSuccess/');
//         app.views.main.router.navigate('/appointment/');
//         app.views.main.router.clearPreviousHistory();
//     } else {
//         app.dialog.alert(returnData['msg']);
//     }
// }

//================= logout ============
function logout() {
    var token = localStorage.getItem("token");
    //====================== update global variable ==============
    app.data.token = '';
    app.data.user.name = '';
    //============= remove from local storage =========
    localStorage.setItem("name", "");
    localStorage.setItem("token", "");
    var url = app.data.remoteUrl + 'userLogout/' + token;
    app.request.json(url, function (data) {

    });
    app.views.main.router.navigate('/sign_in/');
    app.views.main.router.clearPreviousHistory();
}

//================= comming soon ===============
function comingSoon() {
    app.dialog.alert("Coming Soon");
}
function updateTodayDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    var today = yyyy + '/' + mm + '/' + dd;
    app.data.today = today;
    console.log(app.data.today);
}

/*
 *  --  End common function --
 *
 */

/*
 * -- All event will goes here --
 * -- strat -
 */



//================================ login form submit ==============
$$(document).on('submit', '#loginForm', function (e) {
    e.preventDefault();
    console.log("jitlogin");
    app.methods.myAjax('#loginForm', 'login', loginSuccess);
});
//================================ login ==============
$$(document).on('click', '.logout', function (e) {
    logout();
});
//================================ Registration form submit ==============
$$(document).on('submit', '#signUpForm', function (e) {
    e.preventDefault();
    var validation = false;
    var formData = app.form.convertToData('#signUpForm');
    if (formData.password1 != formData.password2) {
        var toastBottom = app.toast.create({
            text: 'Password not match!!',
            position: 'center',
            closeTimeout: 2000,
        });
        toastBottom.open();
        validation = false;
    } else {
        validation = true;
        app.methods.myAjax('#signUpForm', 'signUp', registrationSuccess);
    }
    console.log(formData);
});
//================================ change password form submit ==============
$$(document).on('submit', '#chnagePassForm', function (e) {
    e.preventDefault();
    var validation = false;
    var formData = app.form.convertToData('#chnagePassForm');
    if (formData.password1 != formData.password2) {
        var toastBottom = app.toast.create({
            text: 'Password not match!!',
            position: 'center',
            closeTimeout: 2000,
        });
        toastBottom.open();
        validation = false;
    } else {
        validation = true;
        app.methods.myAjax('#chnagePassForm', 'passChaging', registrationSuccess);
    }
    console.log(formData);
});
//==================== Login Screen Demo =================
$$('#my-login-screen .login-button').on('click', function () {
    var username = $$('#my-login-screen [name="username"]').val();
    var password = $$('#my-login-screen [name="password"]').val();
    // Close login screen
    app.loginScreen.close('#my-login-screen');
    // Alert username and password
    app.dialog.alert('Username: ' + username + '<br>Password: ' + password);
});
//================================ Edit Profile form submit ==============
$$(document).on('submit', '#editProfileForm', function (e) {
    e.preventDefault();
    var formData = app.form.convertToData('#editProfileForm');
    console.log(formData['name']);
    var token = localStorage.getItem("token");
    app.methods.myAjax('#editProfileForm', 'setMyProfile/' + token, function (data) {
        var returnData = JSON.parse(data);
        if (returnData['status']) {
            app.data.user.name = returnData['name'];
            $$('#loginUserName').text(returnData['name']);
            localStorage.setItem("name", returnData['name']);
            app.dialog.alert(returnData['msg'], "Alert");
        } else {
            app.dialog.alert(returnData['msg']);
            app.views.main.router.navigate('/sign_in/');
            app.views.main.router.clearPreviousHistory();
        }
    });
});
//==================== Login Screen Demo ===================
$$('.comingSoon').on('click', function () {
    console.log("coming soon");
    app.dialog.alert("Coming Soon");
});

//=============== Search Bar ==============
//var searchbar = app.searchbar.create({
//    el: '.doctoreSearchbar',
//    searchContainer: '.doctorList',
//    searchIn: '.dr-name',
//    searchItem: '.doctor',
//    on: {
//        search(sb, query, previousQuery) {
//            console.log(query, previousQuery);
//        }
//    }
//});


//document.addEventListener("deviceready", onDeviceReady11, false);
//function onDeviceReady11() {
//
//
//
//}


$$(document).on('click', '#couponApplyAfter .rmv_btn', function (e) {
    punoMushikoBhawa();
});




/*
 *  -- End event --
 */

//======================== booking form page ========================================
$$(document).on('page:init', '.page[data-name="booking_form_"]', function (e, page) {

    var toastBottom = app.toast.create({
        text: 'Doctor Not Available !!',
        position: 'center',
        closeTimeout: 2000,
    });
    //========= date click =============
    $$(document).on('click', '.dateBtn', function (e) {
        if ($$(this).hasClass('inactive')) {
            $$('.dateBtn').removeClass('active');
            $$('#slotTimeArea').html("");
            $$('#doctorBookingDate').val("");
            toastBottom.open();
        } else {
            $$('.dateBtn').removeClass('active');
            $$(this).addClass('active');
            var day = $$(this).data("myday");
            var fullDate = $$(this).data("fullDate");
            var doctor = page.route.context.data.id;
            $$('#doctorBookingDate').val(fullDate);
            console.log(app.data.remoteUrl + 'getDoctorTime/' + doctor + '/' + day + '/');
            app.request({
                url: app.data.remoteUrl + 'getDoctorTime/' + doctor + '/' + day + '/',
                method: 'POST',
                success: function (returndata) {
                    console.log(returndata);
                    $$('#slotTimeArea').html(returndata);
                },
                complete: function () {
                    app.preloader.hide();
                },
                error: function () {
                    app.preloader.hide();
                    app.dialog.alert("Please Check Internet Connection !!", "Alert");
                },
                beforeSend: function () {
                    app.preloader.show();
                },
            });
        }
    });
    //============= Time slot click ==================
    $$(document).on('click', '.slotTimeSection', function (e) {
        if ($$(this).hasClass('active')) {
            $$('#doctorBookingTime').val('');
            var doctorBookingTime = $$(this).text();
            $$('#doctorBookingTime').val(doctorBookingTime);
        } else {
            $$('.slotTimeSection').removeClass('active');
            $$(this).addClass('active');
            var doctorBookingTime = $$(this).html();
            $$('#doctorBookingTime').val(doctorBookingTime);
        }
    });
});
//======================== doctore prebook not available =================
$$(document).on('click', '#DoctorListPage .doc_btn_disable', function (e) {
    app.dialog.alert("Booking will start soon !!", " ");
});
//======================== login details ==================================
$$(document).on('page:init', '.page[data-name="sign_in"]', function (e, page) {

//    var saveToken = localStorage.getItem("token");
//    console.log("token - " + saveToken);


//================================ Mobile Number Submit ==============
//    $$(document).on('submit', '#enterMobileForm', function (e) {
//        e.preventDefault();
////    app.methods.myAjax('#loginForm', 'login', loginSuccess);
//    });

});
//======================== doctor prebook not available =================
$$(document).on('click', '#appoitmentBillPage .printBill', function (e) {
    var appId = $$(this).data("appid");
//    app.dialog.alert("Booking will start soon !!", appId);

    var url = app.data.remoteUrl + 'printBill/' + appId + '/';
    var url = "file:///home/biswa/Downloads/mDOC1574760127.pdf";
    console.log(url);
//    var fileTransfer = new FileTransfer();
//    var uri = encodeURI(url), fileURL = '/var/www/html/mdoc';
//
//    fileTransfer.download(
//            uri,
//            fileURL,
//            function (entry) {
//                console.log("download complete: " + entry.toURL());
//            },
//            function (error) {
//                console.log("download error source " + error.source);
//                console.log("download error target " + error.target);
//                console.log("download error code" + error.code);
//            },
//            false,
////            {
////                headers: {
////                    "Authorization": "Basic dGVzdHVzZXJuYW1lOnRlc3RwYXNzd29yZA=="
////                }
////            }
//            );




    downloader.init();
    downloader.get(url);
});
//======================== chanage city  ================================
$$(document).on('change', '#selectCity', function (e) {
    var city = [];
    var ss = app.smartSelect.get('.smart-select');
    city['id'] = ss.getValue();
//    //------------------ update city value -------------
    updateCity(city);
    app.views.main.router.navigate('/home/');
    app.views.main.router.clearPreviousHistory();
});

$$(document).on('click', '#DoctorListPage .comingSoon', function (e) {
    comingSoon();
});
$$(document).on('click', '#enpBooking .comingSoon', function (e) {
    comingSoon();
});

$$(document).on('click', '#copyCoupon', function (e) {
    var text = $$(this).data("code");
    cordova.plugins.clipboard.copy(text);
});

$$(document).on('click', '#myCouponList .copyCoupun', function (e) {
    var text = $$(this).data("code");
//    console.log(text);
    cordova.plugins.clipboard.copy(text);
});

///============== whatapp share ============
$$(document).on('click', '#whatsAppShare', function (e) {
    var text = $$(this).data("code");
    window.plugins.socialsharing.shareViaWhatsApp(text, null /* img */, null /* url */,
            function () {
                console.log('share ok')
            },
            function (errormsg) {
                console.log(errormsg)
            });
});
$$(document).on('click', '#faceBookShare', function (e) {
    var text = $$(this).data("code");
    window.plugins.socialsharing.shareViaFacebook(text, null /* img */, null /* url */,
            function () {
                console.log('share ok')
            },
            function (errormsg) {
                console.log(errormsg)
            }
    );


});
$$(document).on('click', '#instaShare', function (e) {
    var text = $$(this).data("code");
    window.plugins.socialsharing.share(text);

});
$$(document).on('click', '#anyShare', function (e) {
    var text = $$(this).data("code");
    window.plugins.socialsharing.share(text);
});
//=========================================


function enterMobile(data) {
    console.log(data);
}

function storeSession(returnData) {
    app.data.token = returnData['token'];
    app.data.user.name = returnData['name'];
    app.data.user.city = '1';  //==== default city is Ranchi ========
    $$('#loginUserName').text(returnData['name']);
    localStorage.setItem("token", returnData['token']);
    localStorage.setItem("name", returnData['name']);
    // localStorage.setItem("mobile", returnData['name']);
    localStorage.setItem("city", "1");
}

function destroySession() {

}

// function payment(bookId) {
//     var token = localStorage.getItem("token");
//     var url = app.data.remoteUrl + 'paynow/' + token + '/' + bookId + '/';
//     if (app.device.cordova) {
//         app.preloader.show();
//         var ref = cordova.InAppBrowser.open(url, '_blank', 'location=yes,hideurlbar=yes,hidenavigationbuttons=yes,toolbarcolor=#ffffff,hardwareback=no,closebuttoncolor=#e62379');
//         ref.addEventListener("loadstop", function () {
//             app.preloader.hide();
//         });
//     } else {
//         window.open(url, "_blank");
//     }
// }

function updateCity(city) {
    //console.log(city);
    //app.data.city_name = city['name'];
    app.data.user.city = city['id'];
    //localStorage.setItem("city_name", city['name']);
    localStorage.setItem("city", city['id']);
}


function downloadBill(bookId) {

    var url = app.data.remoteUrl + 'printBill/' + bookId + '/';
    if (app.device.cordova) {
        app.preloader.show();
        var ref = cordova.InAppBrowser.open(url, '_blank', 'location=yes,hideurlbar=yes,hidenavigationbuttons=yes,toolbarcolor=#ffffff,hardwareback=no,closebuttoncolor=#e62379');
        ref.addEventListener("loadstop", function () {
            app.preloader.hide();
        });
    } else {
        window.open(url, "_blank");
    }
}

$$(document).on('click', '#goToLive', function (e) {
    console.log("go live");
    if (app.device.cordova) {
        var ref = cordova.InAppBrowser.open(app.data.paymentUrl, '_self', 'location=no');
    } else {
        window, open(app.data.paymentUrl, "_blank");
    }
    console.log("live hogeya");
});

function punoMushikoBhawa() {
    //=============== update coupon =====================
    $$('#bookingCoupon').val("");
    //=============== update coupon number ==============
    $$('#couponApplyBefor').show();
    $$('#couponApplyAfter').hide();
    //--------------------- update doctor fee calculation ----------------
    var dscnt_amt = $$('#amntdetail .dscnt_amt').data("dscnt_amt");
    var gstAmount = $$('#amntdetail .gstAmount').data("gstAmount");
    var totalAmnt = $$('#amntdetail .totalAmnt').data("totalAmnt");
    $$('#amntdetail .dscnt_amt').text(dscnt_amt);
    $$('#amntdetail .gstAmount').text(gstAmount);
    $$('#amntdetail .totalAmnt').text(totalAmnt);
}