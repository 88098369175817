
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '3fc3240dbf',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page" data-name="DoctorList" id="DoctorListPage">\n        <div class="navbar">\n            <div class="navbar-inner sliding">\n                <div class="left">\n                    <a href="#" class="link back">\n                        <i class="icon icon-back"></i>\n                        <span class="if-not-md">Back</span>\n                    </a>\n                </div>\n                <div class="title">';
      r += c(ctx_1.$route.params.hospital_name, ctx_1);
      r += '</div>\n                <div class="right">\n                    <a class="link icon-only searchbar-enable" data-searchbar=".searchbar-components-doctor">\n                        <i class="icon f7-icons if-not-md">search</i>\n                        <i class="icon material-icons md-only">search</i>\n                    </a>\n                </div>\n                <form data-search-container=".doctorList-list" data-search-in=".doctorNameSearch" class="searchbar searchbar-expandable searchbar-components-doctor searchbar-init">\n                    <div class="searchbar-inner">\n                        <div class="searchbar-input-wrap">\n                            <input type="search" placeholder="Search Doctor"/>\n                            <i class="searchbar-icon"></i>\n                            <span class="input-clear-button"></span>\n                        </div>\n                        <span class="searchbar-disable-button if-not-aurora">Cancel</span>\n                    </div>\n                </form>\n\n            </div>\n        </div>\n        <div class="page-content">\n            <!---- Searchbar backdrop-->\n            <div class="searchbar-backdrop"></div>\n\n            <div class="doctorList-list searchbar-found_ searchbar-hide-on-enable_">\n\n                <ul class="drlist">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <li class="item-content">\n                        <div class="card doctor_list">\n                            <div class="card-content">\n                                <div class="row">\n                                    <div class="col-70">\n                                        <div class="doc">\n                                            <div class="doc-image">\n                                                <div class="doc-image-place" ><img src="';
          r += c(ctx_1.$root.assetsUrl, ctx_2);
          r += 'assets/images/';
          r += c(ctx_2.dp, ctx_2);
          r += '"></div>\n                                            </div>\n                                            <div class="doctor-info-2">\n                                                <p class="dr-name">Dr. \n                                                    <span class="doctorNameSearch">';
          r += c(ctx_2.name, ctx_2);
          r += '</span>\n                                                    ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.isPartnerDoctor == 1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                                        <img class="badge_icon" src="static/badge-check_fill.svg" />\n                                                    ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                                    ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                                </p>\n                                                <p class="dr-experience text-color-theme">';
          r += c(ctx_2.experiance, ctx_2);
          r += '+ Year Exp</p>\n                                                <p class="dr-degree" >';
          r += c(ctx_2.degree, ctx_2);
          r += '</p>\n                                                <p class="department">';
          r += c(ctx_2.dep_name, ctx_2);
          r += '</p>\n                                            </div>\n                                        </div>\n                                    </div>\n                                    <div class="col-30">\n                                        <div class="fee-info" >\n                                            <a href="#" class="comingSoon"><p class="profile text-color-theme"><i class="f7-icons">info_round</i></p></a>\n                                            <p class="appointment-fee">Appointment Fee</p>\n                                            <p class="fee-details text-color-theme">\u20B9 ';
          r += c(ctx_2.fee, ctx_2);
          r += '</p>\n                                        </div>\n                                    </div>\n                                    <div class="col-100">\n                                        ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.preLive == 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        <a href="#" class="doc_btn_disable">\n                                            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                            <a href="/booking/';
              r += c(ctx_3.id, ctx_3);
              r += '/Normal/" class="doc_btn">\n                                                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n                                                <button class="button button-fill button-small">Book Now</button>\n                                            </a>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="card-footer">\n                                <div class="row">\n                                    <div class="col-80">\n                                        <div class="doc_address">\n                                            <div class="address-info-2">\n                                                <a href="#">\n                                                    <p class="clinic-name">';
          r += c(ctx_2.hospital_name, ctx_2);
          r += '</p>\n                                                    <p class="clinic-address">';
          r += c(ctx_2.address, ctx_2);
          r += '</p>\n                                                </a>\n                                            </div>\n                                        </div>\n                                    </div>\n                                    <div class="col-20">\n                                        <div class="directon-icon">\n\n                                            <a class="link external" href="https://www.google.com/maps/place/';
          r += c(ctx_2.lat, ctx_2);
          r += '+';
          r += c(ctx_2.log, ctx_2);
          r += '" target="_system">\n                                                <div class="direction-cs bg-color-theme">\n                                                    <span><i class="icon material-icons">directions</i></span>\n                                                </div>\n                                            </a>\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </li>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n                </ul>\n            </div>\n\n            <!--Nothing found message-->\n            <div class="block searchbar-not-found">\n                <div class="no_avl_cpns_main">\n                    <div class="no_avl_cpns_icon_main">\n                        <img src="static/img/no_doctor.svg">\n                        <p>No Doctor is found with this name</p>\n                    </div>\n                </div>\n            </div>\n            <!--Nothing found message end-->\n\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    