
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '7c83b25044',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page" id="appoitmentBillPage">\n        <div class="navbar">\n            <div class="navbar-inner sliding">\n                <div class="left">\n                    <a href="/home/" class="link">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">Appointments</div>\n                <form data-search-container=".components-list" data-search-in="a" class="searchbar searchbar-expandable searchbar-components searchbar-init">\n                    <div class="searchbar-inner">\n                        <div class="searchbar-input-wrap">\n                            <input type="search" placeholder="Search components"/>\n                            <i class="searchbar-icon"></i>\n                            <span class="input-clear-button"></span>\n                        </div>\n                        <span class="searchbar-disable-button if-not-aurora">Cancel</span>\n                    </div>\n                </form>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="appntmnt-desgin">\n\n                ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.data.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                <a href="/appointmentDetails/';
          r += c(ctx_2.id, ctx_2);
          r += '/">\n                    <div class="card">\n                        <div class="card-content">\n                            <div class="appointment-ticket">\n                                <div class="doctor-image"><img src="static/doctor.png" style="width: 100%"></div>\n                                <div class="movie-details">\n                                    <span class="__title with-mode">Dr. ';
          r += c(ctx_2.doctorName, ctx_2);
          r += ' </span>\n                                    <!--<span class="ticket-mode"><a>View Profile</a></span>-->\n                                    <span class="ticket-mode2">\n\n                                        <img src="static/';
          r += c(ctx_2.image, ctx_2);
          r += '">\n\n                                    </span>\n\n                                    <span class="__lang-dimension">';
          r += c(ctx_2.depName, ctx_2);
          r += '</span>\n                                    ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.is_dateConfirmed, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                    <div class="datetime">\n                                        <span class="__time">';
              r += c(ctx_3.confirmTime, ctx_3);
              r += '</span>\n                                    </div>\n                                    <span class="__venue">Time Status: <span class="color-confirm">Confirmed</span></span>\n                                    ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                    <div class="datetime">\n                                        <span class="__time">';
              r += c(ctx_3.time, ctx_3);
              r += '</span>\n                                    </div>\n                                    <span class="__venue">Time Status: <span class="color-pending">Pending</span></span>\n                                    ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                </div>\n                            </div>\n                            <div class="divider"></div>\n                            <!--==============================This Part Is New Start==========================-->\n                            <div class="appointment_info">\n                                <div class="ticket-quantity">\n                                    <div class="__day">';
          r += c(ctx_2.day, ctx_2);
          r += '</div>\n                                    <div class="__date">';
          r += c(ctx_2.date, ctx_2);
          r += '</div>\n                                    <div class="__month">';
          r += c(ctx_2.month, ctx_2);
          r += '</div>\n                                </div>\n                                <div class="seat-info-container">\n                                    <span class="booking_status" style="display: block;">Booking Status:\n                                        ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.is_bookConfirmed, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        <span class="color-confirm">Confirmed</span>\n                                        ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n                                        <span class="color-pending">Pending</span>\n                                        ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                                    </span>\n                                    <span class="__audiData">Appointment Id :';
          r += c(ctx_2.pre, ctx_2);
          r += c(ctx_2.id, ctx_2);
          r += '</span>\n                                    <span class="__seatNumber">Amount: \u20B9 ';
          r += c(ctx_2.payableAmount, ctx_2);
          r += '</span>\n                                </div>\n                            </div>\n                            <!--==============================This Part Is New End==========================-->\n                            <!--                            <div class="appointment_info">\n                                                            <div class="ticket-quantity">\n                                                                <div class="__day">';
          r += c(ctx_2.day, ctx_2);
          r += '</div>\n                                                                <div class="__date">';
          r += c(ctx_2.date, ctx_2);
          r += '</div>\n                                                                <div class="__month">';
          r += c(ctx_2.month, ctx_2);
          r += '</div>\n                                                            </div>\n                                                            <div class="seat-info-container">\n                                                                <span class="__audiData">Appointment Id : <span>';
          r += c(ctx_2.pre, ctx_2);
          r += c(ctx_2.id, ctx_2);
          r += '</span><span class="__seatNumber"> \u20B9 ';
          r += c(ctx_2.payableAmount, ctx_2);
          r += '</span>\n                                                            </div>\n                                                            <a class="link external" href="';
          r += c(ctx_1.$root.remoteUrl, ctx_2);
          r += 'printBill/';
          r += c(ctx_2.id, ctx_2);
          r += '/"><i class="f7-icons size-29">download</i></a>\n                                                            <a class="printBill" data-appid="';
          r += c(ctx_2.id, ctx_2);
          r += '"><i class="f7-icons">download</i></a>\n                                                        </div>-->\n                        </div>\n                    </div>\n                </a>\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n            </div>\n\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    