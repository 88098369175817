
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  on: {
    pageInit: function (e, page) {
      var self = this;
      var today = new Date();
      var app = self.$app;
      var $ = self.$;
      self.calendarModal = app.calendar.create({
        inputEl: '#demo-calendar-modal',
        openIn: 'customModal',
        header: true,
        footer: true,
        dateFormat: 'yyyy/mm/dd'
      });
    },
    pageBeforeRemove() {
      var self = this;
      self.calendarModal.destroy();
    }
  },
  id: 'c4f3978452',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page edit_profile">\n        <div class="navbar">\n            <div class="navbar-inner sliding">\n                <div class="left link-colors">\n                    <a href="#" class="link back">\n                        <i class="icon icon-back"></i>\n                        <span class="if-not-md">Back</span>\n                    </a>\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="">\n                <div class="card">\n                    <div class="card-content">\n                        <div class="sidebar-header">\n                            <img src="static/default.png" alt="">\n                            <span id="loginUserName">';
      r += c(ctx_1.$root.user.name, ctx_1);
      r += '</span>\n                        </div>\n                        <div>\n                            <form id=\'editProfileForm\'>\n                                <div class="list no-hairlines-md">\n                                    <ul>\n                                        <li class="item-content item-input">\n                                            <div class="item-inner">\n                                                <div class="item-title item-floating-label">Name</div>\n                                                <div class="item-input-wrap">\n                                                    <input type="text" placeholder="" autocomplete="off" name="name" value="';
      r += c(ctx_1.data.name, ctx_1);
      r += '" required validate>\n                                                    <i class="icon material-icons md-only field-icon">person</i>\n                                                </div>\n                                            </div>\n                                        </li>\n\n                                        <li class="item-content item-input">\n                                            <div class="item-inner">\n                                                <div class="item-title item-floating-label">Mobile</div>\n                                                <div class="item-input-wrap">\n                                                    <input type="text" placeholder="Mobile Number" value="';
      r += c(ctx_1.data.mobile, ctx_1);
      r += '" readonly="" required validate>\n                                                    <i class="icon material-icons md-only field-icon">phone_android</i>\n                                                </div>\n                                            </div>\n                                        </li>\n                                        <li class="item-content item-input">\n                                            <div class="item-inner">\n                                                <div class="item-title item-floating-label">E-mail</div>\n                                                <div class="item-input-wrap">\n                                                    <input type="email" placeholder="" name="email" value="';
      r += c(ctx_1.data.email, ctx_1);
      r += '">\n                                                    <i class="icon material-icons md-only field-icon">email</i>\n                                                </div>\n                                            </div>\n                                        </li>\n                                        <li class="item-content item-input">\n                                            <div class="item-inner">\n                                                <div class="item-title item-floating-label">Gender</div>\n                                                <div class="item-input-wrap">\n                                                    <select name="gender" placeholder="Please choose..." class="input-with-value">\n                                                        <option  value="Male"  ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.data.gender == \'Male\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' selected=\'\' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '  >Male</option>\n                                                        <option  value="Female" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.data.gender == \'Female\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' selected=\'\' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '  >Female</option>\n                                                        <option  value="Others" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.data.gender == \'Others\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' selected=\'\' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' >Others</option>\n                                                    </select>\n                                                    <i class="icon material-icons md-only field-icon">wc</i>\n                                                </div>\n                                            </div>\n                                        </li>\n                                        <li class="item-content item-input">\n                                            <div class="item-inner">\n                                                <div class="item-title item-floating-label">DOB</div>\n                                                <div class="item-input-wrap">\n                                                    <input type="text" name="dob"  placeholder="Select date" readonly="readonly" id="demo-calendar-modal" value="';
      r += c(ctx_1.data.dob, ctx_1);
      r += '">\n                                                    <i class="icon material-icons md-only field-icon">date_range</i>\n                                                </div>\n                                            </div>\n                                        </li>\n                                        <li class="item-content item-input">\n                                            <div class="item-inner">\n                                                <div class="item-title item-floating-label">Address</div>\n                                                <div class="item-input-wrap">\n                                                    <input type="text" placeholder="" name="address" value="';
      r += c(ctx_1.data.address, ctx_1);
      r += '" required validate>\n                                                    <i class="icon material-icons md-only field-icon">home</i>\n                                                </div>\n                                            </div>\n                                        </li>\n                                    </ul>\n                                    <div class="row update_btn">\n                                        <div class="col-100">\n                                            <button class="button button-large button-raised button-fill" type="submit">Update</button>\n                                        </div>\n                                    </div>\n                                </div>\n                            </form>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    