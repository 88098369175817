
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '5b8f8f7d2c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner sliding">\n                <div class="left">\n                    <a href="#" class="link back">\n                        <i class="icon icon-back"></i>\n                        <span class="if-not-md">Back</span>\n                    </a>\n                </div>\n                <div class="title">Terms of Use</div>\n            </div>\n        </div>\n        <div class="page-content terms_condition">\n            <div class="card">\n                <div class="card-content">\n                    <div class="terms_heading">\n                        <h1>Terms & Condition</h1>\n                        <h2>End User Agreement</h2>\n                    </div>\n                    <div class="terms_descrptn">\n                        <!--==================Dynamic Content Goes Here=============-->\n                        <p>';
      r += c(ctx_1.data.mainTerms, ctx_1);
      r += '</p>\n                        <p>';
      r += c(ctx_1.data.disclamer, ctx_1);
      r += '</p>\n                        ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.data.details, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                        <p class="terms_subheading">';
          r += c(ctx_2.title, ctx_2);
          r += '</p>\n                        <ol class="terms_list">\n                            ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.chld, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                            <li class="terms_item">';
              r += c(ctx_3, ctx_3);
              r += '</li>\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                        </ol>\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                        <!--==================Dynamic Content Goes Here=============-->\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    