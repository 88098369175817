
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '2fc291c9d1',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page">\n        <div class="navbar">\n            <div class="navbar-inner sliding">\n                <div class="left">\n                    <a href="#" class="link back">\n                        <i class="icon icon-back"></i>\n                    </a>\n                </div>\n                <div class="title">Appointment Details</div>\n            </div>\n        </div>\n        <div class="page-content">\n\n            <div class="card booking">\n                <div class="card-content">\n                    <div class="row">\n                        <div class="col-100">\n                            <div class="doc">\n                                <div class="doc-image">\n                                    <div class="doc-image-icon"><img src="static/apt-icon/user-md-solid-01.svg"></div>\n\n                                </div>\n                                <div class="dctr-info">\n                                    <p class="dr-name">Dr. ';
      r += c(ctx_1.data.doctorName, ctx_1);
      r += '</p>\n                                    <p class="dr-degree" >';
      r += c(ctx_1.data.doctorDegree, ctx_1);
      r += '</p>\n                                    <p class="department">';
      r += c(ctx_1.data.depName, ctx_1);
      r += '</p>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class="card booking">\n                <div class="card-content">\n                    <div class="row">\n                        <div class="col-70">\n                            <div class="doc">\n                                <div class="doc-image">\n                                    <div class="doc-image-icon" ><img src="static/apt-icon/clock-solid2-01.svg"></div>\n                                </div>\n                                <div class="dctr-info">\n                                    <p class="dr-name">';
      r += c(ctx_1.data.day, ctx_1);
      r += ', ';
      r += c(ctx_1.data.date, ctx_1);
      r += ' ';
      r += c(ctx_1.data.month, ctx_1);
      r += '</p>\n                                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data.is_dateConfirmed, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                    <p class="dr-degree" >';
          r += c(ctx_2.data.confirmTime, ctx_2);
          r += '</p>\n                                    <p class="department">Time Status:\n                                        <span class="color-confirm">Confirmed</span>\n                                    </p>\n                                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                    <p class="dr-degree" >';
          r += c(ctx_2.data.time, ctx_2);
          r += '</p>\n                                    <p class="department">Time Status: <span class="color-pending">Pending</span></p>\n                                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                                </div>\n                            </div>\n                        </div>\n                        <div class="col-30">\n                            <div class="fee-info">\n                                <!--<a href=""><p class="date_changer">Change</p></a>-->\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class="card booking">\n                <div class="card-content">\n                    <div class="row">\n                        <div class="col-100">\n                            <div class="doc">\n                                <div class="doc-image">\n                                    <div class="doc-image-icon" ><img src="static/apt-icon/calendar-check-solid-01.svg"></div>\n\n                                </div>\n                                <div class="dctr-info">\n                                    <p class="dr-name">Booking Status :\n                                        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data.is_bookConfirmed, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                        <span class="color-confirm">Confirmed</span>\n                                        ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n                                        <span class="color-pending">Pending</span>\n                                        ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                                    </p>\n                                    <p class="dr-degree" >Appointment Id : ';
      r += c(ctx_1.data.pre, ctx_1);
      r += c(ctx_1.data.id, ctx_1);
      r += '</p>\n                                    <p class="department">1 Ticket: \u20B9 ';
      r += c(ctx_1.data.payableAmount, ctx_1);
      r += '</p>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class="card booking">\n                <div class="card-content">\n                    <div class="row">\n                        <div class="col-100">\n                            <div class="doc">\n                                <div class="doc-image">\n                                    <div class="doc-image-icon" ><img src="static/apt-icon/user-solid2-01.svg"></div>\n\n                                </div>\n                                <div class="dctr-info">\n                                    <p class="dr-name">';
      r += c(ctx_1.data.name, ctx_1);
      r += ' <span>(';
      r += c(ctx_1.data.gender, ctx_1);
      r += '-';
      r += c(ctx_1.data.age, ctx_1);
      r += ')</span></p>\n                                    <p class="dr-degree" >';
      r += c(ctx_1.data.mobile, ctx_1);
      r += '</p>\n                                    <p class="department">';
      r += c(ctx_1.data.address, ctx_1);
      r += '</p>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class="card booking">\n                <div class="card-content">\n                    <div class="row">\n                        <div class="col-80">\n                            <div class="doc">\n                                <div class="doc-image">\n                                    <div class="doc-image-icon" ><img src="static/apt-icon/clinic-medical-solid-01.svg"></div>\n\n                                </div>\n                                <div class="dctr-info">\n                                    <p class="dr-name">';
      r += c(ctx_1.data.hospital_name, ctx_1);
      r += '</p>\n                                    <p class="dr-degree" >';
      r += c(ctx_1.data.doctorAddress, ctx_1);
      r += '</p>\n                                    <!--<a><p class="department">Get Direction</p></a>-->\n                                </div>\n                            </div>\n                        </div>\n                        <div class="col-20" style="padding: 6.5px 0px;">\n                            <div class="directon-icon">\n                                <a class="link external" href="https://www.google.com/maps/place/';
      r += c(ctx_1.data.lat, ctx_1);
      r += '+';
      r += c(ctx_1.data.log, ctx_1);
      r += '" target="_system">\n                                    <div class="direction-cs bg-color-theme">\n                                        <span><i class="icon material-icons">directions</i></span>\n                                    </div>\n                                </a>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class="invoice-action">\n                <div>\n                    <div class="row">\n                        <div class="col-50">\n                            <a class="link external display_block" href="';
      r += c(ctx_1.$root.remoteUrl, ctx_1);
      r += 'printBill/';
      r += c(ctx_1.data.printID, ctx_1);
      r += '/" target="_system">\n                                <div class="card">\n                                    <div class="card-content content-padding">\n                                        <div class="inv-icons">\n                                            <img src="static/apt-icon/file-pdf-regular-01.svg" alt="">\n                                            <h3>Preview Invoice</h3>\n                                        </div>\n                                    </div>\n                                </div>\n                            </a>\n                        </div>\n                        <div class="col-50">\n                            <a href="#" class=" display_block" id=\'invoiceShareButton\' data-url="';
      r += c(ctx_1.$root.remoteUrl, ctx_1);
      r += 'printBill/';
      r += c(ctx_1.data.printID, ctx_1);
      r += '/" >\n                                <div class="card">\n                                    <div class="card-content content-padding">\n                                        <div class="inv-icons">\n                                            <img src="static/apt-icon/share-alt-solid-01.svg" alt="">\n                                            <h3>Share Invoice</h3>\n                                        </div>\n                                    </div>\n                                </div>\n                            </a>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    