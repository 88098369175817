export default {
  id: '8ca8b01727',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page refer_page">\n        <div class="navbar">\n            <div class="navbar-inner sliding">\n                <div class="left">\n                    <a href="#" class="link back">\n                        <i class="icon icon-back"></i>\n                        <span class="if-not-md">Back</span>\n                    </a>\n                </div>\n            </div>\n        </div>\n        <div class="page-content">\n            <div class="refer_section">\n                <div class="refer_img">\n                    <img src="static/img/refer.svg">\n                </div>\n                <div class="refer_txt">\n                    <p class="refer_quatoes">';
      r += c(ctx_1.data.offerLine1, ctx_1);
      r += '</p>\n                    <p class="refer_quatoes">';
      r += c(ctx_1.data.offerLine2, ctx_1);
      r += '</p>\n                    <p class="share_quatoes">Share your referral code</p>\n                    <!--<a><p class="refer_working">How referral works?</p></a>-->\n                </div>\n                <div class="refer_code">\n                    <div class="row refer_code_desgn">\n                        <div class="col-70">\n                            <p class="code">';
      r += c(ctx_1.data.code, ctx_1);
      r += '</p>\n                        </div>\n                        <div class="col-30">\n                            <p class="copy" data-code="';
      r += c(ctx_1.data.code, ctx_1);
      r += '" id="copyCoupon">Copy</p>\n                        </div>\n                    </div>\n                </div>\n                <div class="share_icons">\n                    <p class="invite">Invite Now</p>\n                    <div class="row icons_desgn">\n\n                        <div class="text-align-center" data-code="';
      r += c(ctx_1.data.shareText, ctx_1);
      r += '" id=\'whatsAppShare\'>\n                            <img src="static/img/whatsapp.svg">\n                            <p>Whatsapp</p>\n                        </div>\n                        <div class="text-align-center" data-code="';
      r += c(ctx_1.data.shareText, ctx_1);
      r += '" id=\'faceBookShare\'>\n                            <img src="static/img/fb.svg">\n                            <p>Facebook</p>\n                        </div >\n                        <div class="text-align-center" data-code="';
      r += c(ctx_1.data.shareText, ctx_1);
      r += '" id=\'instaShare\'>\n                            <img src="static/img/insta.svg">\n                            <p>Instagram</p>\n                        </div>\n\n                        <div class="text-align-center" data-code="';
      r += c(ctx_1.data.shareText, ctx_1);
      r += '" id=\'anyShare\'>\n                            <img src="static/img/more.svg">\n                            <p>More</p>\n                        </div>\n\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};